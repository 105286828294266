.App {
  text-align: center;
}
.cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px; /*чтобы нижний навбар не перекрывал контент*/
}
.cont {
  display: flex;
  justify-content: flex-start;
}
.LinkWithoutBlue{
  text-decoration: none;
  color:#212529
}

.scrollToTopButton {
  position: fixed;
  bottom: 45px;
  right: 25px;
  display: none; /* По умолчанию кнопка невидима */
}

.scrollToTopButton.show {
  display: block; /* Показывает кнопку */
}

/*отображения в компоненте UserAccount*/
.UserAccountLessonsdisplay{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

/*отображение кнопки LogOut и личного кабинета в навбаре*/
.LogOutBattonAccountRender{
  display: flex;
  flex-direction: row-reverse;
}

/* Устанавливаем порядок элементов внутри контейнера Navbar */
.navbar-toggle {
  order: 3; /* бургер будет третьим элементом */
}
.navbar-text {
  margin-left: auto; /* LOG IN будет всегда справа */
  margin-right: 4px;
}
.dropdown-item-right {
  text-align: right;
}

/*Tabs bar TabsYg.js*/
.nav-link {
  color: black !important; 
}

.nav-link.active {
  color: white !important; 
  background-color: #212529 !important;
}

/*кружочки для отображения уровня сложности урока*/
.level-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border:#212529 1px solid;
  display: inline-block;
  margin-right: 5px;
}
.white {
  background-color: #f8f9fa;
}
.dark{
  background-color: #212529;
}
.whiteBlack {
  background: linear-gradient(to right, #f8f9fa 50%, #212529 50%);
}

/*размер шрифта в UserAccont*/
.dateInUserAccount{
  font-size: 14px;
}
.FONT{
  font-family: "Philosopher", sans-serif;
}
.FontSecond{
  font-family: "Poppins", sans-serif;
}
.DisplayNoneForThisContent{
  display: none;
}

@media (min-width: 992px) { /* Большие экраны */
  .navbar-collapse {
      justify-content: flex-end; /* Выравнивание элементов меню к правому краю */
  }
}
@media (max-width: 991.98px) {
  .navbar-nav {
      margin-left: auto; /* Выравнивание элементов меню к правому краю */
  }
}
@media (max-width: 858px) {
  .fixed-bottom-mobile {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
  }  
}
@media (max-width: 508px) {
  .level-circle {
    margin-right: 0px;
  }
  .FontMobile{
    font-size: 14px;
  }

  
}