
.addDuration{
    width: 60px;
    border: none;
    border-radius: 5px;
    text-align: right;
}
.YourLessonLengthTime{
    display: flex;
    flex-direction: row;
    gap: 10px;
}