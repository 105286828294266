.PlayerControls{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.Player{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.TotalTimeAndSoundButton{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.PlayerP{
  margin-bottom: 20px;
  text-align: center;
}
.CardAndName{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PlayerCards{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.CircularProgressContainer {
  position: absolute;
  right: 10px; 
  bottom: 10px; 
  width: 100px; 
  height: 100px; 
}
.NavigationButton{
  cursor: pointer;
}
.PlayStopPauseButtons{
  display: flex;
  justify-content: center;
  align-items: center;
}
.PlayPauseButton{
  background: transparent;
  border: none;
}
button{
  border: none;
  background-color: transparent;
}
.SoundButton{
  align-self: flex-end;
  margin-right: 20px;
}
@media all and (max-width:600px){
  .CircularProgressContainer {
    right: 5px; 
    bottom: 5px; 
    width: 50px; 
    height: 50px; 
  }
}