.LoaderPosition {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Растягиваем на всю высоту видимой области экрана */
}
.loader {
    position: relative;
    width: 120px;
    height: 120px;
}

.loader-image {
    background-image: url('../../myAssets/90.png'); /* Путь к вашему изображению */
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.loader::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid #f3f3f3; 
    border-top: 4px solid #43BD01; 
    border-radius: 50%;
    animation: spin 2s linear infinite; /* Анимация только для внешнего круга */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
