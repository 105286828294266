.headline{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 120px;
    padding-bottom: 90px;   
}
.headlineWords{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    margin: 0; /* Удалить внешние отступы между параграфами */
    line-height: 1; /* межстрочный интервал*/
    margin-bottom: 50px;
}
.FontOne{
    font-family: "Philosopher", sans-serif;
}
.FontTwo{
    font-family: "Poppins", sans-serif;
}
.h1MainFont{
    font-family: "Philosopher", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 150px;
    color: #212529;
    /*background: linear-gradient(to right, #F00603, #FD9D12, #EED601, #43BD01, #2BB4DA, #024270, #F17CA1, #731975);
    -webkit-background-clip: text;
    background-clip: text;  
    -webkit-text-fill-color: transparent;
    color: transparent;  */
}
.h2MainFont{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 128px;
    color: transparent; /* Делаем текст прозрачным */
    -webkit-text-stroke: 2px #212529; /* Обводка текста для браузеров на основе WebKit (Chrome, Safari) */
}
.Builder{
    margin-right: 27px;
}
.headlineWords p:nth-last-child(-n+2) {
    line-height: 0.7; /* Уменьшить межстрочный интервал только для последних двух параграфов */
}
.headlineButtonAndVideo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    flex: 1;
    min-width: 300px;

}
.headlineButton{
    display: flex;
    justify-content: center;
    align-self: center;
    flex: 1;
    min-width: 300px;
    text-decoration: none;
    width: 100%;

}
.headlineVideo{
    display: flex;
    justify-content: center;
    align-self: center;
    flex: 1;

}
.visualHeadlineButton{
    background-color: #43BD01;
    width: 50%;
}
/*Membership.js*/
.MembershipColor{
    background-color: #43BD01;
    /* border: #43BD01;*/
}
.MembershipFontColor{
    color: white;
}
.FreeContentColor{
    color:#f00603;
}


/*Features.js*/
.featureMargin{
    margin-top: 150px;
}
.feature-image img {
    width: 1.75em; /* Ширина изображения, соответствующая размеру SVG иконок */
    height: 1.75em; /* Высота изображения, соответствующая размеру SVG иконок */
    object-fit: cover; 
}

.feature-image {
    flex-shrink: 0; /* Предотвращает сжатие изображения */
    margin-right: 1rem; /* Отступ справа, подобно SVG иконкам */
}
.FeatureHeader{
    color: #212529;
    font-family: "Philosopher", sans-serif;
}
/*Footer.js*/
.FooterFont{
    color: #212529;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    text-decoration: none;
}
.Privacy{
    color: #212529;
}

@media all and (max-width:1400px){

    .headlineButton{
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        align-self: center;
        width: 100%;
        flex: 1;
        text-decoration: none;
    }
    .visualHeadlineButton{
        width: 70%;

    }
}


@media all and (max-width:1000px){
    .headlineVideo{
        margin-top: 0px;
        margin-bottom: 0;
    }
    .headlineButton{
        margin-top: 0px;
        align-self: center;
        min-width: 300px;
        justify-content: center;
        padding-right: 0;

    }
    .visualHeadlineButton{
        width: 50%;
    }
    .headline{
        padding-bottom: 200px;   
    }  
    
}


@media all and (max-width:670px){
    .h1MainFont{
        font-size: 130px;
    }
    .h2MainFont{
        font-size: 100px;
    }
    .Builder{
        margin-right: 20px;
    }
    .headline{
        padding-bottom: 50px;   
    }  

    
    
}
@media all and (max-width:550px){
    .h1MainFont{
        font-size: 100px;
    }
    .h2MainFont{
        font-size: 70px;
    }
    .Builder{
        margin-right: 15px;
    }
    .headlineWords p:nth-last-child(-n+2) {
        line-height: 0.6; /* Уменьшить межстрочный интервал только для последних двух параграфов */
    }
    
    

    
}
@media all and (max-width:410px){
    .h1MainFont{
        font-size: 80px;
    }
    .h2MainFont{
        font-size: 60px;
    }
    .Builder{
        margin-right: 12px;
    }
    .headline{
        padding-bottom: 70px;   
    }


    
}

