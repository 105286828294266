.LessonCards{ /*то, как карточки видятся в уроке*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px; /*чтобы нижний навбар не перекрывал контент*/
    background-color: #f8f9fa;
    border: none;
    border-radius: 7px;
    margin-left: 65px; /*для полей в уроке, чтобы не было конфликта с dnd при прокрутке стр*/
    margin-right: 65px; /*для полей в уроке, чтобы не было конфликта с dnd при прокрутке стр*/
    padding-top: 50px;

}

.AsanaCard{
    padding: 15px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AsanaCardStroke{
    margin-bottom: 20px;
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AsanaImage{
    width: 250px;
    height: 228px;
    object-fit: contain;
    display: block; 
    margin: 0 auto; 
    margin-top: 10px;
    margin-bottom: 10px;
}
.Info{
    color: #00000086; 
    display: flex;
    justify-content: center;
    align-items: center;  
}
.AsanaP{
    text-align: center; /* Выравнивание текста по центру */
    max-width: 80%; /* Максимальная ширина элемента, можно адаптировать под ваш дизайн */
    margin: auto; /* Автоматические отступы слева и справа для центрирования блока на странице */
    word-wrap: break-word; /* длинные слова не выходят за границы */
    font-size: 16px;
    line-height: 1.5;
    min-height: 72px;
}

.LessonAddDurationRemoveButton{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%

}
.addDurationPosition{
    align-self: center;
}

/*AboutYogaAsana*/
.PictureAndDescription{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.LevelAndUl{
    align-self: center;
}
.levelMargin{
    margin-left: 30px;
    margin-bottom: 20px;
}
.textDescription{
    width: 100%; /* Ширина текста равна ширине родительского элемента */
    text-align: justify; /* Выравнивание текста по ширине */
    text-justify: inter-word; /* Дополнительное выравнивание текста */
}


@media all and (max-width:1290px){
    .AsanaCard{
        padding: 10px;
        padding-bottom: 60px;
    }
}


@media all and (max-width:820px){

    .AsanaCardStroke{
        width: 200px;    
    }       
    .AsanaImage{
        width: 150px;
        height: 178px;
    }

}
@media all and (max-width:780px){
    .AsanaCard{
        padding: 10px;
        padding-bottom: 40px;
    }
}

@media all and (max-width:700px){
    .AsanaCard{
        padding: 10px;
        padding-top: 0px;
        margin-bottom: 50px;
    }
    .AsanaCardStroke{
        width: 100px;    
        margin-bottom: 0px;
    }
    .AddDurationTimeFontSize{
        font-size: 14px;
    }
    .AsanaImage{
        width: 90px;
        margin: 10px;
        height: auto;
    }
    .MobileButton{
        font-size: 14px;
    }
    .AsanaP{
        display: none;
    }
    .LessonAddDurationRemoveButton{
        margin-top: -15px;
        width: 94px;
    }
    .LessonCards{
        margin-left: 45px; /*для полей в уроке, чтобы не было конфликта с dnd при прокрутке стр*/
        margin-right: 45px; /*для полей в уроке, чтобы не было конфликта с dnd при прокрутке стр*/

    }
    
    

}